import React, { useState } from "react"
import { Layout, Space, Typography, Avatar, Image } from "antd"
import { MenuOutlined, CloseOutlined } from "@ant-design/icons"
import SignOutButton from "./SignOutButton"

const { Header } = Layout
const { Title, Text } = Typography

const AppHeader = ({ user }) => {
    const [showMenuBtn, setShowMenuBtn] = useState(false)

    return (
        <Header className="header">
            <nav>
                <div className="logo">
                    <Title level={1} style={{ color: "#fff", fontSize: "24px" }}>
                        {process.env.REACT_APP_TITLE}
                    </Title>
                </div>

                <div className="menu_btn" onClick={() => setShowMenuBtn((value) => !value)}>
                    {showMenuBtn ? <CloseOutlined /> : <MenuOutlined />}
                </div>

                <ul style={{ marginTop: -5, height: showMenuBtn ? 250 : 0 }}>
                    <li>
                        <Space size={[5, 0]}>
                            {user.avatar === null ? (
                                <Avatar src={null}>
                                    <Text>
                                        {user.firstname[0]}
                                        {user.lastname[0]}
                                    </Text>
                                </Avatar>
                            ) : (
                                <Avatar src={user.avatar} />
                            )}
                            <Text style={{ color: "#fff" }}>{user.fullname}</Text>
                        </Space>
                    </li>
                    <li>
                        <SignOutButton />
                    </li>
                    <li>
                        <Image height={24} src="../assets/DuPont_tm_w.svg" preview={false} />
                    </li>
                </ul>
            </nav>
        </Header>
    )
}

export default AppHeader
