import React, { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Modal, Space } from 'antd';
import { ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/msal/authConfig";
import { useDispatch } from 'react-redux';
import { refreshToken, setUserProfile } from '../redux/actions/authActions';
import moment from 'moment';

 

const SessionTimeout = () => {
    const [seconds, setSeconds] = useState(0);
    const [isSessionTimeout, setIsSessionTimeout] = useState(false);
    const [expiresOn, setExpiresOn] = useState(new Date().getTime() + 30 * 60 * 1000);

 

    const { instance, accounts } = useMsal();
    const dispatch = useDispatch();

 

    useEffect(() => {
        const sessionTimer = setInterval(() => {
            const currentTime = new Date().getTime();

 

            if (expiresOn <= currentTime) {
                setIsSessionTimeout(true);
                setSeconds(300);
                clearInterval(sessionTimer);
            }
        }, 1000);

 

        return () => clearInterval(sessionTimer);
    }, [expiresOn]);

 

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds === 0) {
                //setIsSessionTimeout(false);
                //setIsBlinking(false);
                //setSeconds(null);

 

                clearInterval(timer);

 

               // dispatch(setUserProfile({}));

 

                // const account = instance.getActiveAccount();
                // instance.logout({
                //     account: account,
                //     onRedirectNavigate: (url) => {
                //         // Return false to stop navigation after local logout
                //         return false;
                //     }
                // });

 

            }
            else {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }

 

        }, 1000);

 

        return () => clearInterval(timer);
    }, [seconds])

 

    const handleSession = useCallback(async () => {
        try {
            setSeconds(null);
            setIsSessionTimeout(false);
            // Silently acquires an access token which is then attached to a request for MS Graph data
            await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                const tokenInfo = {
                    token: response.accessToken
                }
                dispatch(refreshToken(tokenInfo));
                setExpiresOn(new Date().getTime() + 30 * 60 * 1000)
                setIsSessionTimeout(false);
            });
        } catch (error) {
            window.location.reload();
        }
    }, []);

 

    // const handleLogout = () => {
    //     dispatch(setUserProfile({}));
    //     const account = instance.getActiveAccount();
    //     instance.logout({
    //         account: account,
    //         onRedirectNavigate: (url) => {
    //             // Return false to stop navigation after local logout
    //             return false;
    //         }
    //     });
    // }

 

    return (
<>
            {/* <div style={{ position: 'absolute', top: 70, right: 15 }}>
                {// Your session will expire in {moment.utc(moment.duration(seconds, 'seconds').as('milliseconds')).format('HH:mm:ss')} seconds. }

                {// Your session expires at {moment.unix(expiresOn / 1000).utc().local().format("hh:mm:ss A")} }

 

                Your session expires at {moment.unix(expiresOn / 1000).utc().local().format("hh:mm:ss A")}
</div> */}

 

            {/* <BlinkingTitle isBlinking={isBlinking} /> */}

 

 

            <Modal
                title=''
                open={isSessionTimeout}
                // onOk={handleSession}
                // onCancel={handleLogout}
                // okText="Yes, Continue"
                // cancelText="No, Sign me out"
                // centered
                width={seconds === 0 ? '25%' : '52%'}
                maskClosable={false}
                closable={false}
                destroyOnClose={true}
                footer={false}
                style={{
                    top: 50,
                }}
>                 
                {seconds === 0
                    ? <div style={{ display: 'flex', alignItems: 'center' }}>
<WarningOutlined style={{ fontSize: 20, color: '#faad14', marginRight: 10 }} />

 

                        <span>You have been logged out.</span>
<Button type='link' onClick={handleSession}>Sign in</Button>
</div>

 

                    : <div style={{ display: 'flex', alignItems: 'center' }}>
<WarningOutlined style={{ fontSize: 20, color: '#faad14', marginRight: 10 }} />

 

                        <span>You will soon be logged out automatically. Do you want to stay logged in?</span>
<Button type='link' onClick={handleSession}>Keep me logged in</Button>
</div>
                }

 

                {/* <div style={{ lineHeight: 2 }}>
                    {seconds <= 30 && <>Your session will expire in <b>{seconds}</b> seconds. <br /></>}

 

                    {seconds > 30 && <>Your session will expire in <b>{moment.utc(moment.duration(seconds, 'seconds').as('milliseconds')).format('HH:mm:ss')}</b> seconds. <br /></>}
                    {// Time Left : {moment.utc(moment.duration(seconds,'seconds').as('milliseconds')).format('HH:mm:ss')} <br/> }
                    Click on "Continue" button to extend your session. <br />
</div> */}
</Modal>
</>
    )
}

 

export default SessionTimeout