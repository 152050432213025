import React from "react"
import { Route, Routes } from "react-router-dom"
import lazyLoadRoutes from "../route/LazyLoadRoutes"
import { useSelector } from "react-redux"

const EstimationGuide = lazyLoadRoutes("EstimationGuide")
const PricingRule = lazyLoadRoutes("PricingRule")
const AccessDenied = lazyLoadRoutes("AccessDenied")
const PageNotFound = lazyLoadRoutes("PageNotFound")

const AppRoutes = () => {
    const { role } = useSelector((state) => state.auth)
    return (
        <Routes>
            <Route exact path="/" element={<EstimationGuide />}>
                <Route path="/Estimationguide" element={<EstimationGuide />} />
            </Route>
            <Route exact path='/Pricingrule' element={role === 'A' ? <PricingRule /> : <AccessDenied />} />
            <Route exact path="/Pricingrule" element={<PricingRule />} />
            <Route path="*" element={<PageNotFound />} /> {/* Wildcard route */}
        </Routes>
    )
}

export default AppRoutes
