import { PRODUCT_ACTIONS } from "../actions/productActionTypes"
const initialState = {
    windings: null,
    productFilmTypes: [],
    productFamilies: [],
    products: [],
    productDetails: null,
    productFamilySelected: null,
    productSelected: null,
    pricingRuleProductFamilySelected: null,
    pricingRuleProductSelected: null,
    endUseCodes: null,
    orderDetails: null,
    windingTypeSelected: null,
    productDetailsByWindings: null,
    units: "english",
    productWeight: {
        WEIGHT_SQFT_LBS: 1,
        WEIGHT_SQM_KG: 1,
        FILM_THICKNESS_MIL: 0,
    },
}

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_ACTIONS.SET_PRODUCT_FILM_TYPES:
            return {
                ...state,
                productFilmTypes: action.payload,
            }
        case PRODUCT_ACTIONS.UPDATE_PRODUCT_FAMILY:
            return {
                ...state,
                productFamilies: action.payload,
                products: [],
            }
        case PRODUCT_ACTIONS.UPDATE_PRODUCT_FAMILY_SELECTED:
            return {
                ...state,
                productFamilySelected: action.payload,
            }
        case PRODUCT_ACTIONS.UPDATE_PRODUCTS:
            return { ...state, products: action.payload }
        case PRODUCT_ACTIONS.UPDATE_PRODUCT_SELECTED:
            return { ...state, productSelected: action.payload, windings: null }
        case PRODUCT_ACTIONS.UPDATE_WINDING_TYPE:
            return { ...state, windings: action.payload }
        case PRODUCT_ACTIONS.UPDATE_PRODUCT_DETAILS:
            return { ...state, productDetails: action.payload }
        case PRODUCT_ACTIONS.UPDATE_END_USE_CODE:
            return { ...state, endUseCodes: action.payload }
        case PRODUCT_ACTIONS.UPDATE_ORDER_DETAILS:
            return { ...state, orderDetails: action.payload }
        case PRODUCT_ACTIONS.UPDATE_PRODUCT_WEIGHT:
            return { ...state, productWeight: action.payload }
        case PRODUCT_ACTIONS.UPDATE_WINDING_TYPE_SELECTED:
            return { ...state, windingTypeSelected: action.payload }
        case PRODUCT_ACTIONS.UPDATE_PRODUCT_DETAILS_BY_WINDINGS:
            return { ...state, productDetailsByWindings: action.payload }
        case PRODUCT_ACTIONS.UPDATE_UNITS_SELECTED:
            return { ...state, units: action.payload }
        case PRODUCT_ACTIONS.UPDATE_PRODUCT_FAMILY_SELECTED_PRICING_RULE:
            return { ...state, pricingRuleProductFamilySelected: action.payload }
        case PRODUCT_ACTIONS.UPDATE_PRODUCT_SELECTED_PRICING_RULE:
            return { ...state, pricingRuleProductSelected: action.payload }
        default:
            return state
    }
}

export default productReducer
