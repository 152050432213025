export const PRODUCT_ACTIONS = {
    UPDATE_WINDING_TYPE: `UPDATE_WINDING_TYPE`,
    UPDATE_PRODUCT_FAMILY: `UPDATE_PRODUCT_FAMILY`,
    UPDATE_PRODUCT_DETAILS: `UPDATE_PRODUCT_DETAILS`,
    UPDATE_PRODUCT_FAMILY_SELECTED: `UPDATE_PRODUCT_FAMILY_SELECTED`,
    UPDATE_PRODUCT_FAMILY_SELECTED_PRICING_RULE: `UPDATE_PRODUCT_FAMILY_SELECTED_PRICING_RULE`,
    UPDATE_PRODUCTS: `UPDATE_PRODUCTS`,
    UPDATE_PRODUCT_SELECTED: `UPDATE_PRODUCT_SELECTED`,
    UPDATE_PRODUCT_SELECTED_PRICING_RULE: `UPDATE_PRODUCT_SELECTED_PRICING_RULE`,
    UPDATE_END_USE_CODE: `UPDATE_END_USE_CODE`,
    UPDATE_ORDER_DETAILS: `UPDATE_ORDER_DETAILS`,
    UPDATE_PRODUCT_WEIGHT: `UPDATE_PRODUCT_WEIGHT`,
    UPDATE_WINDING_TYPE_SELECTED: `UPDATE_WINDING_TYPE_SELECTED`,
    SET_PRODUCT_FILM_TYPES: "SET_PRODUCT_FILM_TYPES",
    UPDATE_PRODUCT_DETAILS_BY_WINDINGS: `UPDATE_PRODUCT_DETAILS_BY_WINDINGS`,
    UPDATE_UNITS_SELECTED: `UPDATE_UNITS_SELECTED`
}
